import { Anchor, BigBtn, Btn, Line } from '../components/styled/ui'
import { Column, Container, Grid, Section } from '../components/styled/layout'
import { Heading, Intro, Title } from '../components/styled/typography'
import { Link, StaticQuery, graphql } from 'gatsby'

import ArrowRight from '../assets/icons/baseline-arrow_forward-16px_black.svg'
import Img from 'gatsby-image'
import JoinNewsletter from '../components/UI/JoinNewsletter'
import Layout from '../components/layout'
import Projects from '../components/projects/Projects'
import React from 'react'
import SEO from '../components/SEO/SEO'
import Truncate from 'react-truncate'

const EventPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query EventPageQuery {
          allAirtable(
            filter: { table: { eq: "Sidor" }, data: { Sida: { eq: "Event" } } }
          ) {
            edges {
              node {
                data {
                  Sida

                  Huvudbild {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  Uppdrag {
                    data {
                      Titel
                      Rubrik
                      Path
                      Beskrivning {
                        childMarkdownRemark {
                          html
                        }
                      }
                      Bilder {
                        localFiles {
                          childImageSharp {
                            fluid(maxWidth: 900) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const sida = data.allAirtable.edges[0].node.data
        return (
          <React.Fragment>
            <SEO title={'Eventbyrå'} />
            <Layout>
              <Section>
                <Container>
                  <Grid columns="1fr 2fr">
                    <Column flex col space="space-between">
                      <div>
                        <Title>
                          Eventbyrå <span>Vi skapar upplevelser.</span>
                        </Title>
                        <p>
                          Ingen uppgift är för stor … ingen detalj är för liten.
                          Creandia består av ett team av hjältar: idérika,
                          noggranna och servicemedvetna. Allt för att skapa
                          unika och hållbara evenemang.
                        </p>
                      </div>
                      <Anchor href="#more" down>
                        Läs mer <ArrowRight />
                      </Anchor>
                    </Column>
                    <Column>
                      <Img
                        fluid={
                          sida.Huvudbild.localFiles[0].childImageSharp.fluid
                        }
                      />
                    </Column>
                  </Grid>
                </Container>
              </Section>
              <Section id="more">
                <Container width="900px">
                  <Column>
                    <Heading>
                      Event är varumärkesbyggande, relationsstärkande & riktigt,
                      riktigt j*kla kul.
                    </Heading>
                    <Intro subtle style={{ marginBottom: '3vmax' }}>
                      Evenemang handlar inte bara om en rolig upplevelse, det
                      kan även vara ett verktyg som företag och organisationer
                      kan uttnytja för att skapa en stark och äkta påverkan i en
                      allt mer artificiell värld.
                    </Intro>
                    <p>
                      Minnen kan få oss att skratta, att gråta… ja, till och med
                      att känna kärlek. Med ett event så har vi möjligheten att
                      påverka människors åsikter, uppfattningar och känslor om
                      sig själva, om ert företag eller om en hel stad genom att
                      skapa upplevelser och minnen som fäster sig. Oavsett om vi
                      pratar om evenemang i teambuilding-syfte eller som
                      eventmarknadsföring så ger evenemang, oss som företag,
                      organisation eller verksamhet, chansen att stärka våra
                      varumärken.
                      <br /> <br /> När vi arrangerar ett evenemang så får vi
                      chansen att skapa nya relationer och att förbättra de vi
                      redan har. Genom att investera i människor så investerar
                      vi indirekt i oss själva.
                    </p>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Varumärket</h3>
                        <p>
                          Skapa en positiv känsla kring ditt varumärke genom att
                          ge människor en positiv upplevelse.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Relationer</h3>
                        <p>
                          Evenemang ger verksamheter chansen att skapa och
                          förbättra personliga relationer, både internt och
                          externt.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Budskapet</h3>
                        <p>
                          I samband med en fantastisk upplevelse kan vi med en
                          stark slagverkan förmedla ert budskap till rätt
                          målgrupp.
                        </p>
                      </Column>
                    </Grid>
                  </Column>
                </Container>
              </Section>
              <Section>
                <Container>
                  <Grid columns="1fr 1fr" nogap>
                    <Column>
                      <Link
                        style={{ height: '100%' }}
                        to={sida.Uppdrag[0].data.Path}
                      >
                        <Img
                          style={{ height: '100%' }}
                          fluid={
                            sida.Uppdrag[0].data.Bilder.localFiles[0]
                              .childImageSharp.fluid
                          }
                        />
                      </Link>
                    </Column>
                    <Column
                      bg="#f4f4f4"
                      space="4vmax"
                      flex
                      col
                      align="flex-start"
                    >
                      Evenemangsuppdrag
                      <Heading>
                        {sida.Uppdrag[0].data.Rubrik}.{' '}
                        <span>{sida.Uppdrag[0].data.Titel}.</span>
                      </Heading>
                      <Truncate lines={1} ellipsis={'...'}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              sida.Uppdrag[0].data.Beskrivning
                                .childMarkdownRemark.html,
                          }}
                        />
                      </Truncate>
                      <Btn to={sida.Uppdrag[0].data.Path}>
                        Läs mer <ArrowRight />
                      </Btn>
                    </Column>
                  </Grid>
                </Container>
              </Section>
              <Section>
                <Container width="900px">
                  <Column>
                    <Heading>
                      Kompetens, erfarenhet och kreativitet - grunderna till ett
                      lyckat evenemang.
                    </Heading>
                    <Intro subtle style={{ marginBottom: '3vmax' }}>
                      Det kvittar vad evenemangets syfte är, att skapa ett
                      lyckat event är svårt. Det är många pusselbitar som ska
                      falla på plats och det är inte ovanligt att oförutsedda
                      händelser uppstår. Därför är kompetens viktigt, men
                      erfarenheten ännu viktigare.
                    </Intro>
                    <p>
                      I sju år har vi producerat en stadsfest som ska
                      tillfredställa 300.000 personer, kommunen och alla företag
                      som går in och stödjer projektet. Samtidigt får vi inte
                      tappa fokus på evenemangets ursprung. Lärdomarna från
                      detta är värdefullare än något annat, och varje år lär man
                      sig något nytt. Creandia är i grund och botten
                      problemlösare. Vår kompetens visar hur vi ska göra, vår
                      erfarenhet visar vad vi ska göra och vår kreativitet gör
                      att vi kan genomföra evenemangsuppdrag på nya och
                      spännande sätt.
                    </p>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Förarbete</h3>
                        <p>
                          Vi planerar, strukturerar och marknadsför evenemang
                          utifrån uppdragsgivarens vision och målgrupp.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Genomförande</h3>
                        <p>
                          Vi har stenkoll på varenda aspekt av ditt event, och
                          vi genomför med självsäkerhet. Problem uppstår, vi
                          löser dem.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Efterarbete</h3>
                        <p>
                          Vi utvärderar evenemanget och ger dig en solklar bild
                          på resultatet. Vi skickar våra foto, film efter era
                          önskemål.
                        </p>
                      </Column>
                    </Grid>
                  </Column>
                </Container>
              </Section>
              <Section>
                <Container>
                  <Line />
                  <Heading style={{ marginBottom: '4vmax' }}>
                    Evenemang. <span>Ett urval av vad vi gjort.</span>
                  </Heading>
                  <Projects limit={4} filter="Evenemang" />
                </Container>
              </Section>
              <Section style={{ display: 'none' }}>
                <Container mobCover>
                  <Column bg="#f4f4f4" space="5vmax">
                    <Column>
                      <Line />{' '}
                      <Heading>
                        Evenemangstjänster. <span>Detta kan vi erbjuda.</span>
                      </Heading>{' '}
                    </Column>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Eventmarknadsföring</h3>
                        <p>
                          Vi utvärderar evenemanget och ger dig en solklar bild
                          på resultatet. Vi skickar våra foto, film efter era
                          önskemål.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Teambuilding</h3>
                        <p>
                          Vi utvärderar evenemanget och ger dig en solklar bild
                          på resultatet. Vi skickar våra foto, film efter era
                          önskemål.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Konferens</h3>
                        <p>
                          Vi utvärderar evenemanget och ger dig en solklar bild
                          på resultatet. Vi skickar våra foto, film efter era
                          önskemål.
                        </p>
                      </Column>
                    </Grid>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Kickoff / Invigning</h3>
                        <p>
                          Vi utvärderar evenemanget och ger dig en solklar bild
                          på resultatet. Vi skickar våra foto, film efter era
                          önskemål.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Shower</h3>
                        <p>
                          Vi utvärderar evenemanget och ger dig en solklar bild
                          på resultatet. Vi skickar våra foto, film efter era
                          önskemål.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Större evenemang</h3>
                        <p>
                          Vi utvärderar evenemanget och ger dig en solklar bild
                          på resultatet. Vi skickar våra foto, film efter era
                          önskemål.
                        </p>
                      </Column>
                    </Grid>
                    <Section
                      padding="5vmax 0px 0px 0px"
                      style={{
                        borderTop: '1px solid #d8d8d8',
                        marginTop: '5vmax',
                      }}
                    >
                      <Grid columns="2fr 1fr">
                        <Column>
                          <Heading>
                            Ingen uppgift är för stor.{' '}
                            <span>Ingen detalj är för liten.</span>
                          </Heading>
                        </Column>
                        <Column flex col align="center" justify="center">
                          <BigBtn to="/kontakt">Kontakta oss</BigBtn>
                        </Column>
                      </Grid>
                    </Section>
                  </Column>
                </Container>
              </Section>
            </Layout>
          </React.Fragment>
        )
      }}
    />
  )
}

export default EventPage
